import React from 'react'
import { Grid } from '@material-ui/core'
import TeamCard from './TeamCard'

export default function TeamCards({ cardList = [] }) {
    return (
        <div>
            <Grid container spacing={5} alignItems='stretch' justifyContent='center'>
                {
                    cardList.map((item) => {
                        return <Grid item key={item.id} xs={12} md={5}>
                            <TeamCard data={item} />
                        </Grid>
                    })
                }
            </Grid>
        </div>
    )
}
